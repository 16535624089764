var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.game)?_c('div',{key:("mykey-" + _vm.updateKey),staticClass:"lean-config-startingstock pt-2 pb-3"},[(_vm.allFaciStartingStocks)?_c('b-field',{staticClass:"mb-2",attrs:{"position":"is-centered","grouped":""}},[_vm._l((Object.values(_vm.game.params.productsTemplates).sort(
        function (a, b) {
          return a.template_id.localeCompare(b.template_id);
        }
      )),function(pt){return _c('b-field',{key:("sst-" + (pt.template_id)),staticClass:"mr-5",attrs:{"grouped":""}},[_c('p',{staticClass:"control pt-3  "},[_c('b',[_vm._v(_vm._s(pt.name)+":")])]),_c('b-input',{staticClass:"pt-2",attrs:{"min":0,"max":99,"type":"number"},model:{value:(_vm.allFaciStartingStocks[pt.template_id]),callback:function ($$v) {_vm.$set(_vm.allFaciStartingStocks, pt.template_id, _vm._n($$v))},expression:"allFaciStartingStocks[pt.template_id]"}})],1)}),_c('p',{staticClass:"control"},[_c('b-button',{attrs:{"type":"is-info","rounded":"","disabled":!_vm.allFaciStartingStocks},on:{"click":function($event){return _vm.updateAll()}}},[_vm._v("Apply to all stations")])],1)],2):_vm._e(),_c('div',{staticClass:"is-divider divider-text-darker",attrs:{"data-content":"Or customize for each station :"}}),_c('div',{staticClass:"columns is-centered is-multiline"},_vm._l((_vm.facis),function(faci){return _c('div',{key:faci.id,staticClass:"column is-3 has-text-centered"},[_c('h4',{staticClass:"label"},[_vm._v(_vm._s(faci.name)+":")]),_vm._l((Object.values(_vm.game.params.productsTemplates).sort(
          function (a, b) {
            return a.template_id.localeCompare(b.template_id);
          }
        )),function(pt){return _c('div',{key:("sst-" + (pt.template_id) + "-" + (faci.id))},[_c('b-field',{staticClass:"mr-5",attrs:{"grouped":"","horizontal":"","label":((pt.template_id) + ":")}},[_c('b-input',{staticClass:"pt-2",attrs:{"min":0,"max":99,"type":"number"},on:{"input":function($event){_vm.updateKey += 1}},model:{value:(faci.params.startingStock[pt.template_id]),callback:function ($$v) {_vm.$set(faci.params.startingStock, pt.template_id, _vm._n($$v))},expression:"faci.params.startingStock[pt.template_id]"}})],1)],1)})],2)}),0)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }