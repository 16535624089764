<template>
  <div
    class="lean-config-startingstock pt-2 pb-3"
    v-if="game"
    :key="`mykey-${updateKey}`"
  >
    <b-field
      position="is-centered"
      grouped
      class="mb-2"
      v-if="allFaciStartingStocks"
    >
      <b-field
        v-for="pt in Object.values(game.params.productsTemplates).sort(
          (a, b) => {
            return a.template_id.localeCompare(b.template_id);
          }
        )"
        :key="`sst-${pt.template_id}`"
        grouped
        class="mr-5"
      >
        <p class="control pt-3  ">
          <b>{{ pt.name }}:</b>
        </p>
        <b-input
          v-model.number="allFaciStartingStocks[pt.template_id]"
          :min="0"
          :max="99"
          class="pt-2"
          type="number"
        ></b-input>
      </b-field>
      <p class="control">
        <b-button
          @click="updateAll()"
          type="is-info"
          rounded
          :disabled="!allFaciStartingStocks"
          >Apply to all stations</b-button
        >
      </p>
    </b-field>
    <div
      class="is-divider divider-text-darker"
      data-content="Or customize for each station :"
    ></div>
    <div class="columns is-centered is-multiline">
      <div
        class="column is-3 has-text-centered"
        v-for="faci in facis"
        :key="faci.id"
      >
        <h4 class="label">{{ faci.name }}:</h4>
        <div
          v-for="pt in Object.values(game.params.productsTemplates).sort(
            (a, b) => {
              return a.template_id.localeCompare(b.template_id);
            }
          )"
          :key="`sst-${pt.template_id}-${faci.id}`"
        >
          <b-field
            grouped
            class="mr-5"
            horizontal
            :label="`${pt.template_id}:`"
          >
            <b-input
              v-model.number="faci.params.startingStock[pt.template_id]"
              :min="0"
              :max="99"
              class="pt-2"
              type="number"
              @input="updateKey += 1"
            ></b-input>
          </b-field>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LeanConfigStartingStock",
  props: {
    game: Object
  },
  computed: {
    facis() {
      return Object.values(this.newFacilities);
    }
  },
  mounted() {
    this.initializeData();
  },
  methods: {
    initializeData() {
      this.readyWatch = false;
      this.newFacilities = JSON.parse(JSON.stringify(this.game.facilities));
      this.facis.forEach(ff => {
        ff.params.startingStock = {
          ...ff.params.startingStock
        };
        Object.values(this.game.params.productsTemplates).forEach(pt => {
          if (!ff.params.startingStock[pt.template_id]) {
            ff.params.startingStock[pt.template_id] = 0;
          }
        });
        if (!this.allFaciStartingStocks && ff.type !== "inventoryShipping") {
          this.allFaciStartingStocks = ff.params.startingStock
            ? JSON.parse(JSON.stringify(ff.params.startingStock))
            : 0;
        }
      });
      setTimeout(() => {
        this.readyWatch = true;
      }, 5);
    },
    updateAll() {
      this.facis.forEach(fac => {
        if (fac.params) {
          fac.params.startingStock = JSON.parse(
            JSON.stringify(this.allFaciStartingStocks)
          );
        }
      });
      this.updateKey += 1;
    }
  },
  watch: {
    updateKey() {
      if (this.readyWatch) {
        this.$emit("update", {
          type: "startingstock",
          obj: this.newFacilities
        });
      }
    }
  },
  data() {
    return {
      updateKey: 0,
      readyWatch: false,
      newFacilities: {},
      allFaciStartingStocks: null
    };
  }
};
</script>

<style lang="scss">
.lean-config-startingstock {
}
</style>
